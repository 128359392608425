/* @flow */
import React from "react"

type Props = {
    name: string,
    organization: string,
    email: string,
}

export default class Userbox extends React.Component<Props> {
    render() {
        return(
            <div className="userbox">
                <p>GBS Demo App</p>
                {/* <p>{this.props.name}</p>
                <p>{this.props.email}</p>
                <p>{this.props.organization}</p> */}
            </div>
        )
    }
}