// @flow 
import React from "react"
import classnames from "classnames"

import IconAngle from "../icons/IconCircledAngle"

type Direction = "left"|"right"|"up"|"down"

type Props = {
    className: string,
    direction: "leftright"|"updown"|"vertical"|"horizontal",
    toggled?: ?boolean,
    onClick: Function,
    invert?: boolean
}

const directionMap = {
    leftright: ["left", "right"],
    updown: ["up", "down"],
    vertical: ["up", "down"],
    horizontal: ["left", "right"]
}

export default class ToggleButtonDirection extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    get toggleState(): boolean {
        return this.props.invert ? !this.props.toggled : !!this.props.toggled
    }

    get direction(): Direction {
        const selector = this.toggleState ? 1 : 0
        return directionMap[this.props.direction][selector]
    }

    get className(): string {
        const customClassnameToggled = this.props.className + "--toggled"
        return classnames(this.props.className, {
            "togglebutton togglebutton--direction": true,
            "togglebutton--toggled": this.toggleState,
            [customClassnameToggled]: this.toggleState,
        })
    }

    render() {
        return(
            <button className={this.className} onClick={this.props.onClick}>
                <IconAngle direction={this.direction} />
            </button>
        )
    }
}