/* @flow */
import * as React from "react"
import screenfull from "screenfull"
import classnames from "classnames"

import Strip from "../molecules/Strip"
import ToggleButtonDirection from "../atoms/buttons/ToggleButtonDirection"
import ToggleButton from "../atoms/buttons/ToggleButton"
import IconExpand from "../atoms/icons/IconExpand"

type Props = {
    children?: React.Node
}

type State = {
    sidebarCollapsed: boolean,
    fullscreenToggled: boolean,
    pageTitle?: string,
    reportTitle?: string,
}

export default class SuperSidebar extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            sidebarCollapsed: false,
            fullscreenToggled: false,
        }
    }

    onClickFullscreenToggle() {
        screenfull.toggle()
        this.setState({
            fullscreenToggled: !this.state.fullscreenToggled
        })
    }

    onClickSidebarToggle() {
        this.setState({
            sidebarCollapsed: !this.state.sidebarCollapsed
        })
    }

    render() {

        const classname = classnames(
            "supersidebar",
            {
                "supersidebar--collapsed": this.state.sidebarCollapsed
            }
        )

        return(
            <nav className={classname}>
                <div className="supersidebar__content">
                    {this.props.children}
                </div>
                <div className="supersidebar__sidestrip">
                    <Strip vertical>
                        <ToggleButtonDirection 
                            className="supersidebar__togglebutton"
                            direction="horizontal" 
                            toggled={this.state.sidebarCollapsed} 
                            onClick={this.onClickSidebarToggle.bind(this)} />
                        <ToggleButton
                            toggled={this.state.fullscreenToggled}
                            onClick={this.onClickFullscreenToggle.bind(this)} >
                            <IconExpand />
                            <IconExpand />
                        </ToggleButton>
                    </Strip>
                    <div>
                        <Strip className="supdersidebar__title-container" vertical>
                            {this.state.pageTitle && <span className="supersidebar__page-title">Page Title</span>}
                            {this.state.reportTitle && <span className="supersidebar__report-title">Report Title</span>}
                        </Strip>
                    </div>
                </div>
            </nav>
        )
    }
}