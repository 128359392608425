import React from "react"

import classnames from "classnames"

type Props = {
    className: string,
    horizontal?: boolean,
    vertical?: boolean
}

export default class Strip extends React.Component<Props> {
    
    get variantClassname(): string {
        if(this.props.horizontal) {
            return "strip--horizontal"
        } else if(this.props.vertical) {
            return "strip--vertical"
        } else {
            return ""
        }
    }

    get classname(): string {
        return classnames(
            "strip", 
            this.variantClassname,    
            this.props.className
        )
    }

    render(): React.Element {
        return(
            <div className={this.classname}>
                {this.props.children}
            </div>
        )
    }
}