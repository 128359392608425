/* @flow */
import React from "react"

import classnames from "classnames"

type Props = {
    className?: string
}

export default class IconExpand extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    get classname(): string {
        return classnames(this.props.className, "svgicon")
    }

    render() {
        return(
            <svg className={this.classname} viewBox="0 0 64.062500 64.062500" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.000000 2.656250 L20.000000 -0.000000 L0.000000 -0.000000 L0.000000 20.000000 L2.656250 20.000000 L2.656250 4.531250 L21.718750 23.593750 L23.593750 21.718750 L4.531250 2.656250 Z M44.062500 -0.000000 L44.062500 2.656250 L59.531250 2.656250 L40.468750 21.718750 L42.343750 23.593750 L61.406250 4.531250 L61.406250 20.000000 L64.062500 20.000000 L64.062500 -0.000000 Z M21.718750 40.468750 L2.656250 59.531250 L2.656250 44.062500 L0.000000 44.062500 L0.000000 64.062500 L20.000000 64.062500 L20.000000 61.406250 L4.531250 61.406250 L23.593750 42.343750 Z M61.406250 59.531250 L42.343750 40.468750 L40.468750 42.343750 L59.531250 61.406250 L44.062500 61.406250 L44.062500 64.062500 L64.062500 64.062500 L64.062500 44.062500 L61.406250 44.062500 Z M72.031250 67.031250"/>
            </svg>
        )
    }
}