// @flow
import settings from "./settings"
import * as React from "react"
import * as _powerbi from "powerbi-client"

// import superPowerStore from "./stores/superPowerStore"

/**
 * too much stuff in here
 */
class PowerbiGuy {
    currentReport: Object
    embedContainerClassname: string
    embedContainerElement: ?HTMLElement
    pages: Object
    embedBaseConfig: {
        embedUrl: string,
        settings: Object,
        tokenType: Object,
    }

    constructor() {
        this.embedContainerClassname = ".powerframe"
        this.embedBaseConfig = {
            tokenType: _powerbi.models.TokenType.Aad,
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false
            }
        }
    }

    get embedContainer() {
        if(this.embedContainerElement) {
            return this.embedContainerElement
        } else {
            this.embedContainerElement = document.querySelector(this.embedContainerClassname)
            return this.embedContainerElement
        }
    }

    getAccessToken() {
        return fetch(settings.server)
            .then(r => r.text())
            .catch(e => console.log(e))
    }

    embed(id: string, type: string, embedCallback: Function) {
        this.getAccessToken().then(accessToken => {
            let embedCallbackData = {}
            console.log("fetched token: ", accessToken)
            const reportConfig = {id: id, accessToken: accessToken, type: type}
            const embedConfiguration = Object.assign({}, this.embedBaseConfig, reportConfig)
            console.log("embedConfig: ", embedConfiguration)
            // window.powerbi? how can I use the imported library?
            const pbiEmbed = window.powerbi.embed(this.embedContainer, embedConfiguration)
            console.log("pbiEmbed: ", pbiEmbed)

            pbiEmbed.on("loaded", event => {
                if(type === "report") {
                    pbiEmbed.getPages().then(pages => embedCallback(pages))
                }
            })
        })
    }
}

export default new PowerbiGuy()