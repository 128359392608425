// @flow
import * as React from "react"
import classnames from "classnames"
import powerbiGuy from "../../powerbiGuy"

import ListyPages from "./ListyPages"
import ToggleButton from "../atoms/buttons/ToggleButtonDirection"

type ListyItem = {
    id: string,
    title: string,
    showPages: boolean,
    type: "dashboard"|"report",
    items?: Array<ListyItem>,
    embedObject: Object
}

type Props = {
    items: Array<ListyItem>,
    level?: number,
    embedUrl?: string,
    collapsed?: boolean
}

class ListyButton extends React.Component<Object, Object> {

    onClick() {
        const {type, id, callback} = this.props
        const pbiEmbed = powerbiGuy.embed(id, type, callback)
    }

    render() {
        const classname = classnames("button listy__button listy__button--embedurl")
        return(<button onClick={this.onClick.bind(this)} className={classname}>{this.props.children}</button>)
    }
}

class RecursiveListy extends React.Component<Object, Object> {
    state: {
        pages?: Array<Object>,
        collapsed: boolean
    }

    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
        }
    }

    render() {
        const {title, items, type, id, showPages, level} = this.props
        const {pages, collapsed} = this.state
        console.log("items", title, "level" + level, items)
        const toggleable = (items && items.length) || (pages && pages.length && showPages)
        const actuallyShowPages = (showPages && pages && pages.length) && !items
        return(
            <li className="listy__item">
                <div className="listy__button-group">
                    <ListyButton 
                        type={type} 
                        id={id}
                        callback={data => this.setState({pages: data.slice(0)})}>
                        {title}
                    </ListyButton>
                    {toggleable && <ToggleButton
                        onClick={() => this.setState({collapsed: !collapsed})}
                        toggled={collapsed}
                        direction={"vertical"} 
                        className={"listy__toggler"}/>}
                </div>
                {items && <Listy collapsed={collapsed} items={items} level={level} />}
                {actuallyShowPages && pages && <ListyPages reportId={id} collapsed={collapsed} pages={pages} level={level}/>}
            </li>
        )
    }
}

export default class Listy extends React.Component<Props> {
    level: number
    nextLevel: number

    constructor(props: Props) {
        super(props)
        this.level = props.level ? props.level : 0
        this.nextLevel = this.level + 1
    }

    render() {
        const classname = classnames(
            "listy listy--level" + this.level,
            {
                "listy--collapsed": this.props.collapsed
            }
        )
        return(
            <ul className={classname}>
                {this.props.items.map(item => <RecursiveListy {...item} key={item.id} level={this.nextLevel} />)}
            </ul>
        )
    }
}
