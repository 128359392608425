// @flow
import React from "react";

import Listy from "./playground/Listy"
import PowerFrame from "./playground/Powerframe"
import SuperSidebar from "./playground/SuperSidebar"
import Userbox from "./playground/Userbox"

import appTree from "../tree"

type Props = {}

export default class SuperPower extends React.Component<Props> {
    render() {
        return (
            <main id="main" className="superpower">
                <PowerFrame />
                <SuperSidebar>
                    <Userbox 
                        name={"Lino"}
                        email={"som@mail.com"}
                        organization={"navio"} />
                    <div className={"superpower__nav"}>
                        <Listy items={appTree} />
                    </div>
                </SuperSidebar>
            </main>
        );
    }
}
