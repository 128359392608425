/* @flow */
import * as React from "react"

import classnames from "classnames"

type Props = {
    className?: string,
    toggled: boolean,
    onClick: Function,
    invert?: boolean,
    // Icon: React.ComponentType<any>,
    // ToggledIcon: React.ComponentType<any>,
    children: [React.Element<Object>, React.Element<Object>]
}

export default class ToggleButton extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    get toggleState(): boolean {
        return this.props.invert ? !this.props.toggled : this.props.toggled
    }

    get className(): string {
        let classnamesArray = [
            "togglebutton togglebutton--icon",
            {"togglebutton--toggled": this.toggleState}
        ]
        if(this.props.className) {
            const customClassnameToggled = this.props.className + "--toggled"
            classnames.push(this.props.className)
            classnames.push({[customClassnameToggled]: this.toggleState})
        }
        return classnames(...classnamesArray)
    }

    // renderIcon() {
    //     switch (this.toggleState) {
    //         case true:
    //             const ToggledIcon = this.props.ToggledIcon
    //             return (<ToggledIcon className={"togglebutton__icon"} />)
    //         default:
    //             const Icon = this.props.Icon
    //             return (<Icon className={"togglebutton__icon"} />)
    //     }
    // }

    renderIcon() {
        if(this.toggleState) {
            return this.props.children[0]
        } else {
            return this.props.children[1]
        }
    }

    render() {
        return(
            <button className={this.className} onClick={this.props.onClick}>
                {this.renderIcon()}
            </button>
        )
    }
}