// @flow
import React from "react"

type Props = {}

export default class PowerFrame extends React.Component<Props> {
    render() {
        return(
            <div className="powerframe">
                <iframe className="powerframe__frame" />
            </div>
        )
    }
}