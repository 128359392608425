// @flow
import * as React from "react"
import powerbiGuy from "../../powerbiGuy"
import classnames from "classnames"

type Props = {
    reportId: string,
    pages?: Array<Object>,
    level: number,
    collapsed: boolean
}

export default class ListyPages extends React.Component<Props> {
    render() {
        const {pages, reportId, level, collapsed} = this.props
        const classname = classnames(
            "listy listy--pages listy--level" + level,
            {"listy--collapsed": collapsed}
        )
        return(
            <ul className={classname}>
                {pages && pages.map(page => {
                    const {displayName, isActive, report, name} = page
                    return(
                        <li className={"listy__item"} key={name}>
                            <button onClick={() => page.setActive()} className={"button listy__button"}>
                                {displayName}
                            </button>
                        </li>
                    )
                })}
            </ul>
        )
    }
}
