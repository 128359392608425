/* @flow */

export default [
    {
        "id": "4f437562-34cd-4b34-a11e-7051b73f3a36",
        "title": "BIBPM-Heatmap",
        "showPages": false,
        "type": "report",
        "embedObject": {
            "id": "4f437562-34cd-4b34-a11e-7051b73f3a36", 
            "modelId": 0, 
            "name": "BIBPM-Heatmap",
            "webUrl": "https://app.powerbi.com/reports/4f437562-34cd-4b34-a11e-7051b73f3a36",
            "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=4f437562-34cd-4b34-a11e-7051b73f3a36", 
            "isOwnedByMe": true, 
            "isOriginalPbixReport": false, 
            "datasetId": "3c38859f-c1d8-49ff-a55e-8fe105c53523"
        },
        // items: [{
        //     "id": "4f437562-34cd-4b34-a11e-7051b73f3a36",
        //     "title": "BIBPM-Heatmap",
        //     "showPages": false,
        //     "type": "report",
        //     "embedObject": {
        //         "id": "4f437562-34cd-4b34-a11e-7051b73f3a36", 
        //         "modelId": 0, 
        //         "name": "BIBPM-Heatmap",
        //         "webUrl": "https://app.powerbi.com/reports/4f437562-34cd-4b34-a11e-7051b73f3a36",
        //         "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=4f437562-34cd-4b34-a11e-7051b73f3a36", 
        //         "isOwnedByMe": true, 
        //         "isOriginalPbixReport": false, 
        //         "datasetId": "3c38859f-c1d8-49ff-a55e-8fe105c53523"
        //     }
        // }, {
        //     "id": "f1898755-49ab-4f2f-993d-2d126f9bc409",
        //     "title": "BIBPM-O2C",
        //     "showPages": true,
        //     "type": "report",
        //     "embedObject": {
        //         "id": "f1898755-49ab-4f2f-993d-2d126f9bc409", 
        //         "modelId": 0, 
        //         "name": "BIBPM-O2C", 
        //         "webUrl": "https://app.powerbi.com/reports/f1898755-49ab-4f2f-993d-2d126f9bc409", 
        //         "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=f1898755-49ab-4f2f-993d-2d126f9bc409", 
        //         "isOwnedByMe": true, 
        //         "isOriginalPbixReport": false, 
        //         "datasetId": "3d361592-e8ef-4973-b21f-c7950e5b22c1"
        //     }
        // }]
    }, {
        "id": "f1898755-49ab-4f2f-993d-2d126f9bc409",
        "title": "BIBPM-O2C",
        "showPages": true,
        "type": "report",
        "embedObject": {
            "id": "f1898755-49ab-4f2f-993d-2d126f9bc409", 
            "modelId": 0, 
            "name": "BIBPM-O2C", 
            "webUrl": "https://app.powerbi.com/reports/f1898755-49ab-4f2f-993d-2d126f9bc409", 
            "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=f1898755-49ab-4f2f-993d-2d126f9bc409", 
            "isOwnedByMe": true, 
            "isOriginalPbixReport": false, 
            "datasetId": "3d361592-e8ef-4973-b21f-c7950e5b22c1"
        }
    }
]